<template>
  <div class="service-times-presentations template-2">
    <page-header class="mx-lg">
      <h1>{{ translations.tcGideonCardDetails }}</h1>
      <BackJump @reload_host="handleFacilityChange()" :obj="backJumpObj" :vis="true" :i18n="translations.components"></BackJump>
    </page-header>

    <page-body class="mx-lg">
      <section class="section-1">
        <div class="bg-block p-4">
          <header class="mb-2">
            <h4>{{ profileSummary.org_name }}</h4>
          </header>
          <div class="d-flex row details">
            <div class="col">
              <div class="detail">
                <strong>{{ translations.tcAssignedNumber }}:</strong>
                <span>{{ profileSummary.camp_assigned_number }}</span>
              </div>
              <div class="detail">
                <strong>{{ translations.tcFacilityType }}:</strong>
                <span>{{ facilityType }}</span>
              </div>
              <div class="detail">
                <strong>{{ translations.tcGideonCardDisplays }}: </strong>
                <span>{{ profileSummary.gideoncard_display_number }}</span>
              </div>
            </div>
            <div class="col">
              <div class="detail">
                <strong>{{ translations.tcLastVideo }}: </strong>
                <span>{{ profileSummary.last_gideon_card_video_showing ? profileSummary.last_gideon_card_video_showing.substring(0, 10) : '' }}</span>
              </div>
              <div class="detail full-detail">
                <strong>{{ translations.tcWebsite }}: </strong>
                <span>
                  <a :href="websiteURL" target="_blank" v-if="profileSummary.url">{{ profileSummary.url }}</a>
                </span>
              </div>
            </div>
            <div class="col">
              <div class="detail full-detail">
                <strong>{{ translations.tcOtherInformation }}: </strong>
                <span>{{ profileSummary.note }}</span>
              </div>
            </div>
          </div>
          <div class="body">
            <div class="d-flex">
              <b-button
                v-if="iCanSee(secured_gideoncard_facility_edit_controls.edit_gideoncard_facility_button)"
                variant="primary"
                @click="handleEditClick"
                class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0"
                >{{ translations.tcEdit }}</b-button
              >
              <b-button
                v-if="iCanSee(secured_gideoncard_facility_delete_controls.delete_gideoncard_facility_button)"
                variant="primary"
                @click="handleDeleteClick"
                class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0"
                >{{ translations.tcDelete }}</b-button
              >
            </div>
          </div>
        </div>
      </section>
    </page-body>

    <page-footer class="mx-lg">
      <BackJump :obj="backJumpObj" :vis="false" :i18n="translations.components"></BackJump>
    </page-footer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from 'vuex'
import BackJump from '@/components/back-jump/BackJumpGideonCards.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'card_details',
  mixins: [translationMixin],
  data() {
    return {
      facilityType: '',
      backJumpObj: {
        link: '/programs/gc/summary/',
        location: '',
        key: null,
      },
      secured_gideoncard_facility_edit_controls: {
        edit_gideoncard_facility_button: '9407664b-79cf-4a0e-837d-a9fb27d56714',
      },
      secured_gideoncard_facility_delete_controls: {
        delete_gideoncard_facility_button: '2c0c7056-195d-48fe-a70b-a4b06ea42aa3',
      },
      translations: {},
    }
  },
  methods: {
    ...mapActions({
      loadProfile: 'card/getProfile',
      setLoadingStatus: 'menu/setLoadingStatus',
      upsertFacility: 'card/upsertFacility',
      setSelectedFacilityKey: 'user/setSelectedFacilityKey',
    }),
    async pageLoad() {
      const result = this.loadProfile(this.userSelectedFacilityKey).then(() => {
        this.backJumpObj.link = `/programs/gc/profile/summary/card_profile`
        this.backJumpObj.location = this.profileSummary.org_name
      })
      await result
      this.updateDynamicText()
    },
    async handleEditClick() {
      this.$router.push({ name: 'card_facility_edit_facility' })
    },
    async handleDeleteClick() {
      this.$swal({
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        cancelButtonText: this.translations.tcCancel,
        confirmButtonText: this.translations.tcOk,
        text: this.translations.tcDeleteGideonCardDetailsMessage,
      }).then((result) => {
        if (result.value) {
          let org = {
            org_name: this.profileSummary.org_name,
            org_key: this.profileSummary.gcf_org_key,
            ind_key: this.userId,
            delete:true
          }
          this.handleDeleteRequest(org)
        }
      })
    },
    async handleDeleteRequest(data) {
      try {
        this.setLoadingStatus(true)
        let response = await this.upsertFacility(data)
        this.setLoadingStatus(false)
        if (response != '') {
          this.$router.push({ name: 'gideon-card-facilities' })
          return
        }
      } catch (error) {
        this.setLoadingStatus(false)
        this.$swal({
          icon: 'error',
          confirmButtonText: this.translations.tcOk,
          text: this.translations.tcDeleteGideonCardErrorMessage,
        })
      }
    },
    async handleFacilityChange() {
      await this.setSelectedFacilityKey(this.userSelectedFacilityKey)
      this.pageLoad()
    },
    updateDynamicText() {
      if (!this.translations.common || !this.profileSummary) return false

      // Facility Type
      const facilityType = this.profileSummary.org_type_name
      this.facilityType = facilityType ?
        this.textLookup(facilityType, 'placement-facility-types', 'commonText') : ''

      return true
    },
  },
  async created() {
    this.setLoadingStatus(true)
    await Promise.all([
      this.getViewTranslations(),
      this.getComponentTranslations('back-jump-gideon-cards', 'quick-jump-gideon-cards'),
      this.getComponentTranslations('common.placement-facility-types'),
    ]).then((results) => {
      this.stripReadableText(results[2], 'text')
      const translatedText = {
        ...results[1],
        common: { ...this.translations.common }
      }
      this.$set(this.translations, 'components', translatedText)
      this.pageLoad()
      })
      .finally(() => {
        this.setLoadingStatus(false)
      })
  },
  computed: {
    ...mapGetters({
      iCanSee: 'user/iCanSee',
      profileSummary: 'card/getFacilitySummary',
      userSelectedChurchKey: 'user/userSelectedChurchKey',
      userSelectedFacilityKey: 'user/userSelectedFacilityKey',
      userId: 'user/userId',
      prefCulture: 'user/userPreferredCulture',
    }),
    websiteURL() {
      if (!this.profileSummary.url) {
        return ''
      }
      return this.profileSummary.url.indexOf('http://') === -1  && this.profileSummary.url.indexOf('https://') === -1 ? `http://${this.profileSummary.url}` : this.profileSummary.url

    }
    // last_video_showing() {
    //   return !this.detail.last_gideon_card_video_showing ? '' : this.detail.last_gideon_card_video_showing.substr(0, 10)
    // },
    // last_presentation() {
    //   return !this.detail.last_presentation_date ? '' : this.detail.last_presentation_date.substr(0, 10)
    // },
  },
  components: {
    BackJump: BackJump,
    pageHeader: pageHeader,
    pageBody: pageBody,
    pageFooter: pageFooter,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
.service-times-presentations {
  .page-header h1 {
    margin-bottom: 50px;
  }
  h2 {
    margin: 0;
    color: #000000;
    font-family: $DIN;
    font-size: 42px;
    letter-spacing: 1.4px;
    line-height: 44px;
    text-transform: uppercase;
    display: flex;
  }
  section {
    margin-bottom: 40px;
    .p-4 {
      padding: 40px 30px !important;
      @include breakpoint(sm) {
        padding: 20px 24px 20px 24px !important;
      }
    }
    header {
      display: flex;
    }
    @include breakpoint(sm) {
      .btn {
        width: 100%;
        margin-bottom: 20px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
  @include breakpoint(sm) {
    .section-1 {
      .ml-4 {
        margin-left: 0 !important;
      }
    }
  }
  .service-times,
  .pres-dates {
    margin-top: 35px;
  }
  .pres-dates {
    .date-card {
      width: calc(33.333% - 15px);
      margin-right: 15px;
      @include breakpoint(sm) {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}

.details {
  margin-bottom: 50px;
  @include breakpoint(sm) {
    flex-wrap: wrap;
  }
  .col {
    flex: 0 0 33.3333%;
    width: 33.3333%;
    @include breakpoint(sm) {
      flex: 0 0 100%;
      width: 100%;
    }
  }
  .detail {
    display: flex;
    align-items: flex-start;
    font-size: 15px;
    strong,
    span {
      display: block;
      color: $gray;
      letter-spacing: 1px;
      line-height: 28px;
    }
    strong {
      flex: 0 0 auto;
      width: 210px;
      padding-right: 10px;
      text-transform: uppercase;
      font-weight: 900;
    }
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    span {
      flex: 1 1 auto;
      align-self: flex-end;
    }
    &.full-detail {
      display: block;
    }
  }
}
</style>

